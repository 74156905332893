import 'react-dates/initialize'
import 'regenerator-runtime/runtime'
import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import messages from '../../../consts/messages'
import s from './NavigationBar.module.scss'
import { LanguagePicker } from '../../../modules/Language/components/Selects'
import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import { configureScope } from '@sentry/browser'
import { connect } from 'react-redux'
import { changeLocale, getLocale, getMobileMenuStatus, toggleMobileMenu } from '../../../services/user-selections'
import { getToken } from '../../../services/auth/reducers/selectors'
import { goToPage } from '../../../actions'
import { addIndex, compose, keys, length, lt, map, path, reduce } from 'ramda'
import { menuItemsSelector } from '../../../services/sales-static/selectors'
import { olderBrowser } from '../../../utils/browserUtils'
import { signout } from '../../../services/auth/actions'
import { userEmailSelector } from '../../../services/user/selectors'
import { getMaritimeStyle, isMaritime, isBluewave } from '../../../utils/maritimeStyleUtils'
import MenuItem from './MenuItem/MenuItem'
import { getIsMobile, getIsSmallScreen, getIsTablet } from '../../../reducers/screenWidth'

const transformMenuItemsForAuthorized = (menuItems, actions) => {
  const logoutMenuItem = {
    title: '/auth/logout',
    translationObj: messages.logOut,
    clickHandler: actions.signout,
    link: '/auth/logout',
    className: 'logIn',
  }

  const profileMenuItem = {
    link: '/profile/settings',
    translationObj: messages.profile,
  }

  const profileCredentials = {
    link: '/profile/credentials',
    translationObj: messages.profile,
  }

  const getAuthLinks = (acc, linkItem) => {
    if (isMaritime && linkItem.link === '/auth/signup') {
      return [...acc, profileCredentials, logoutMenuItem]
    }

    if (linkItem.link === '/auth/signup') {
      return [...acc, profileMenuItem]
    }

    if (isMaritime && linkItem.link === '/auth/login') {
      return [...acc, profileCredentials, logoutMenuItem]
    }

    if (linkItem.link === '/auth/login') {
      return [...acc, logoutMenuItem]
    }

    return [...acc, linkItem]
  }

  return reduce(getAuthLinks, [], menuItems)
}

const styles = {
  link: { color: 'inherit', textDecoration: 'none' },
  width100: { width: '100%' },
  icon: { position: 'absolute', right: '14px', top: '35px', zIndex: 1 },
  widthAuto: { width: '100%', ...(olderBrowser && { width: '995px' }) },
  heightAuto: { height: 'auto' },
  width200: { width: '200px' },
}

const menuItemsForFF = ['Special offers', 'Infromation', 'Agencies', 'Fast Friends', 'Virtual Tour']

const NavigationBar = (props, context) => {
  const { intl, muiTheme } = context
  const { authToken, userEmail = `guest@${navigator.platform}`, locale } = props
  const { isTablet, isMobile, isSmallScreen } = props
  let { menuItems } = props

  if (isMaritime && !isTablet) {
    // or other theme with 2 menu rows
    menuItems = menuItems.filter(({ line }) => line === 2)
  }

  configureScope((scope) => {
    scope.setUser(userEmail)
  })

  if (authToken) menuItems = transformMenuItemsForAuthorized(menuItems, { signout: props.signout })

  const authLinkTypes = {
    '/auth/signup': 'signUp',
    '/profile/settings': 'signUp',
    '/profile/credentials': 'profile',
    '/auth/login': 'logIn',
    '/auth/logout': 'logIn',
  }
  const authLinks = Object.keys(authLinkTypes)

  if (isMaritime && isTablet) {
    const boldMenuItems = menuItems.filter(({ line }) => line === 1).filter(({ modal }) => !modal)
    const smallMenuItems = menuItems.filter(({ line, link }) => line === 2 && !authLinks.includes(link))
    const authMenuItems = menuItems.filter(({ link }) => authLinks.includes(link))
    menuItems = [...boldMenuItems, ...authMenuItems, ...smallMenuItems]
  }

  const generateLabels = addIndex(map)((text, i) => (
    <UIText key={i} id="nav-bar-label" whiteSpace="nowrap" margin="0 20px" text={text} size="14px" />
  ))

  const getMenuItemsFromTheme = (menu, className) =>
    addIndex(map)((item, i) => {
      const menuItem = {}
      menuItem.title = {}
      menuItem.title.rendered = item
      menuItem.slug = path([item], menu)
      menuItem.id = i
      menuItem.className = className
      return menuItem
    }, keys(menu))

  const generateLinks = map(
    ({ subject: title, id, link, translationObj = messages.navBarBookTickets, className, clickHandler, line }) => {
      const authButtonsStyle = authLinkTypes[link]

      const handleClick = (e) => {
        e.preventDefault()
        props.toggleMobileMenu()

        if (clickHandler) clickHandler()
        else {
          props.goToPage(link)
        }
      }

      let loginButtonMobileMargin

      if (isSmallScreen) {
        loginButtonMobileMargin = (locale !== 'ar' && '0 30px 0 0') || '0 0 0 22px'
      }

      if (isTablet) {
        loginButtonMobileMargin =
          link === '/auth/login' && ((locale !== 'ar' && '75px 66% 40px 0') || (locale === 'ar' && '75px 72% 40px 0'))
      }

      if (isMobile) {
        loginButtonMobileMargin =
          link === '/auth/login' && ((locale !== 'ar' && '75px 75% 40px 0') || (locale === 'ar' && '75px 83% 40px 0'))
      }

      if (isMaritime && isTablet) {
        const isButton = Boolean(authButtonsStyle)
        return (
          <MenuItem
            isButton={isButton}
            label={title || intl.formatMessage(translationObj)}
            link={link}
            locale={locale}
            bold={line === 1}
            handleClick={handleClick}
          />
        )
      }

      return (
        <div
          className={cn(className, s.link, s[authButtonsStyle], { [s.olderBrowser]: olderBrowser })}
          key={id || translationObj.id}
          data-test={`top-nav-${link}`}
          style={{ margin: loginButtonMobileMargin, fontSize: getMaritimeStyle(isMobile && '20px') }}
        >
          <a href={link} className={s.linkElement} onClick={handleClick}>
            <div id="nav-bar-label" className={s.linkText}>
              {title || intl.formatMessage(translationObj)}
            </div>
          </a>
        </div>
      )
    }
  )

  const showLanguagePicker = compose(lt(1), length, path(['brandProps', 'languages']))(window) && !isBluewave

  let style = {}
  let AdditionalMenuStyle = {}
  let linkClass = ''

  switch (window.brandProps.theme) {
    case 'fastferries':
      return (
        <UILayout center margin="0" style={styles.width100} xs={0}>
          <UIText
            id="nav-bar-label"
            cursor="pointer"
            whiteSpace="nowrap"
            margin="0 20px"
            width="auto"
            text="Routes - Prices"
          />
          {generateLabels(menuItemsForFF)}
        </UILayout>
      )
    case 'seajets':
      style = {
        margin: '40px 0 0 0',
      }
      AdditionalMenuStyle = {
        margin: '0',
      }
      linkClass = s.seajets
    case 'tallink': // eslint-disable-line
      return (
        <UILayout center margin="0" style={styles.widthAuto} xs={0} data-test="top-menu-wrap" {...style}>
          {menuItems ? generateLinks(menuItems) : generateLinks(getMenuItemsFromTheme(muiTheme.content.menu))}
          <UILayout
            j-flex-end
            center
            position="absolute"
            right="0"
            top="0"
            margin="0"
            style={styles.width200}
            xs={0}
            data-test="top-menu-one"
          >
            <LanguagePicker display-if={showLanguagePicker} locale={props.locale} onChange={props.changeLocale} />
            {generateLinks(getMenuItemsFromTheme(muiTheme.content.loginMenu, linkClass))}
          </UILayout>
          <UILayout
            j-flex-end
            margin="0 0 0 90px"
            style={styles.width100}
            xs={0}
            data-test="top-menu-two"
            {...AdditionalMenuStyle}
          >
            {generateLinks(getMenuItemsFromTheme(muiTheme.content.AdditionalMenu))}
          </UILayout>
        </UILayout>
      )
    case 'admaritime':
      return (
        <div className={cn(s.root, { [s.opened]: props.mobileMenuOpened, [s.olderBrowser]: olderBrowser })}>
          <div
            className={s.toggleIconHolder}
            data-test="mobile-menu-toggle-action"
            onClick={() => props.toggleMobileMenu()}
          >
            <UIIcon
              className={cn(s.closeIcon, { [s.arabic]: props.locale === 'ar' })}
              display-if={props.mobileMenuOpened}
              type="iconClose"
            />
            <UIIcon className={s.openIcon} display-if={!props.mobileMenuOpened} type="iconMenu" />
          </div>
          <div
            className={s.navItemsHolder}
            data-test="top-menu-mobile"
            style={props.locale === 'ar' && !(isMobile || isTablet) ? { flexDirection: 'row-reverse' } : {}}
          >
            {menuItems && generateLinks(menuItems)}
          </div>
        </div>
      )
    case 'bluewave':
      return (
        <div className={s.bluewaveNavbar}>
          {menuItems.map(({ subject, link, id }) => (
            <div className={s.bluewaveNavbarLink} key={id} onClick={() => props.goToPage(link)}>
              {subject}
            </div>
          ))}
        </div>
      )
    default:
      return (
        <div className={cn(s.root, { [s.opened]: props.mobileMenuOpened, [s.olderBrowser]: olderBrowser })}>
          <div
            className={s.toggleIconHolder}
            data-test="mobile-menu-toggle-action"
            onClick={() => props.toggleMobileMenu()}
          >
            <UIIcon className={s.closeIcon} display-if={props.mobileMenuOpened} type="iconClose" />
            <UIIcon className={s.openIcon} display-if={!props.mobileMenuOpened} type="iconMenu" />
          </div>
          <div className={s.navItemsHolder} data-test="top-menu-mobile">
            {menuItems && generateLinks(menuItems)}
            <LanguagePicker display-if={showLanguagePicker} locale={props.locale} onChange={props.changeLocale} />
          </div>
        </div>
      )
  }
}

NavigationBar.propTypes = {
  locale: PropTypes.string.isRequired,
  changeLocale: PropTypes.func.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
  menuItems: PropTypes.array,
  mobileMenuOpened: PropTypes.bool,
}

NavigationBar.defaultProps = {
  menuItems: null,
  mobileMenuOpened: false,
}

NavigationBar.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

export default connect(
  (state) => ({
    locale: getLocale()(state),
    authToken: getToken(state),
    menuItems: menuItemsSelector(state),
    mobileMenuOpened: getMobileMenuStatus(state),
    userEmail: userEmailSelector(state),
    isTablet: getIsTablet(state),
    isMobile: getIsMobile(state),
    isSmallScreen: getIsSmallScreen(state),
  }),
  { changeLocale, toggleMobileMenu, goToPage, signout }
)(NavigationBar)
