import { UIButton, UILayout, UIText, UITextField } from 'bora-material-ui'
import React from 'react'
import messages from '../../../../consts/messages'
import { translate } from '../../../Common/Translator'
import { checkDate, reversedDateStringToDefaultFormat } from './utils'
import RadioButtonGroup from './RadioButtonGroup'
import GroupPicker from './GroupPicker'
import { connect } from 'react-redux'
import { getGroups } from '../../../../services/passengers-data/selectors'
import { showModal } from '../../../../actions'
import { addMember, modifyMember } from '../../../../services/passengers-data/actions'
import { defaultDataStringToReversedFormat } from '../../../Profile/containers/ProfilePassengersData/SavedPassengersDataSection/utils'
import { correctSymbolsRegExp } from '../../../../utils/validate'

const genderDataSource = [
  { value: 'M', label: messages.male },
  { value: 'F', label: messages.female },
]

const styles = {
  textField: { boxShadow: 'none', height: '36px', minHeight: 'auto', border: '1px solid #D4D4D4', borderRadius: '4px' },
  inputStyle: { background: 'transparent' },
  radioButton: {
    display: 'block',
    width: '24px',
  },
}

const modalTitles = {
  edit: messages.addOrEditPassengerDataModalTitle,
  create: messages.addOrEditPassengerDataModalTitle,
  addGroup: messages.addGroupsToExistingPassengerModalTitle,
}

class CreateOrEditPassengerDataModal extends React.Component {
  state = {
    firstName: this.props.data.firstName,
    firstNameError: '',
    lastName: this.props.data.lastName,
    lastNameError: '',
    birthday: defaultDataStringToReversedFormat(this.props.data.birthday),
    birthdayError: '',
    gender: this.props.data.gender,
    selectedGroupIds: this.props.data.groupIds,
  }

  handleDoneButtonClick = () => {
    const { data: { operation, customerId } = {} } = this.props
    const { addMember, modifyMember, closeModal } = this.props
    const { firstName, lastName, birthday, gender, selectedGroupIds } = this.state
    const normalizedBirthday = reversedDateStringToDefaultFormat(birthday)
    if (operation === 'create') {
      addMember({
        firstName,
        lastName,
        gender,
        birthday: normalizedBirthday,
        companyIds: selectedGroupIds,
      })
      closeModal()
      return
    }
    if (['edit', 'addGroup'].includes(operation)) {
      modifyMember({
        id: {
          customerBoraId: customerId,
        },
        firstName,
        lastName,
        gender,
        birthday: normalizedBirthday,
        companyIds: selectedGroupIds,
      })
      closeModal()
    }
  }

  handleFirstNameChange = (value) => {
    this.setState({
      firstNameError: '',
      firstName: value,
    })
  }

  handleFirstNameBlur = () => {
    const { firstName } = this.state
    const isFirstNameCorrect = correctSymbolsRegExp.test(firstName)
    if (!isFirstNameCorrect) {
      this.setState({
        firstNameError: translate(messages.invalidFormatText),
      })
    }
  }

  handleLastNameChange = (value) => {
    this.setState({
      lastNameError: '',
      lastName: value,
    })
  }

  handleLastNameBlur = () => {
    const { lastName } = this.state
    const isLastNameCorrect = correctSymbolsRegExp.test(lastName)
    if (!isLastNameCorrect) {
      this.setState({
        lastNameError: translate(messages.invalidFormatText),
      })
    }
  }

  handleGenderCheck = (e, value) => {
    this.setState({
      gender: value,
    })
  }

  handleBirthdayChange = (value) => {
    this.setState({
      birthdayError: '',
      birthday: value,
    })
  }

  handleBirthdayBlur = () => {
    const { birthday } = this.state
    const isDateCorrect = checkDate(birthday)
    if (!isDateCorrect) {
      this.setState({
        birthdayError: translate(messages.invalidDateError),
      })
    }
  }

  handleSelectGroupsChange = (event) => {
    const {
      target: { value },
    } = event
    this.setState({
      selectedGroupIds: value,
    })
  }

  render() {
    const {
      allGroups,
      data: { operation },
    } = this.props
    const { firstName, firstNameError, lastName, lastNameError, birthday, birthdayError, gender, selectedGroupIds } =
      this.state
    const isDateCorrect = checkDate(birthday)
    const isFirstNameCorrect = correctSymbolsRegExp.test(firstName)
    const isLastNameCorrect = correctSymbolsRegExp.test(lastName)
    const shouldDisableDoneButton = !(isDateCorrect && isFirstNameCorrect && isLastNameCorrect)
    const isCreateOrEditModal = ['edit', 'create'].includes(operation)

    return (
      <UILayout column width="500px" padding="0 10px 30px 10px" bgColor="white" xs={{ width: '100%' }}>
        <UILayout borderBottom="1px solid #C1CADE" padding="0 0 20px 0">
          <UIText font="ADPortsGroupBold, sans-serif" size="18px" color="#2D3955" translate={modalTitles[operation]} />
        </UILayout>

        <UILayout column>
          <UILayout margin="20px 0 10px 0" display-if={isCreateOrEditModal}>
            <UILayout width="50%" center>
              <UIText size="14px" color="#000000" translate={messages.firstName} />
            </UILayout>
            <UILayout width="50%" position="relative">
              <UITextField
                id="firstName"
                value={firstName}
                change={this.handleFirstNameChange}
                onBlur={this.handleFirstNameBlur}
                hintText=""
                style={styles.textField}
                inputStyle={styles.inputStyle}
              />
              <UILayout display-if={firstNameError} j-flex-center position="absolute" top="40px">
                <UIText font="ADPortsGroupBold, sans-serif" size="10px" color="#D11800" text={firstNameError} />
              </UILayout>
            </UILayout>
          </UILayout>

          <UILayout margin="10px 0 10px 0" display-if={isCreateOrEditModal}>
            <UILayout width="50%" center>
              <UIText size="14px" color="#000000" translate={messages.lastName} />
            </UILayout>
            <UILayout width="50%" position="relative">
              <UITextField
                id="lastName"
                value={lastName}
                change={this.handleLastNameChange}
                onBlur={this.handleLastNameBlur}
                hintText=""
                style={styles.textField}
                inputStyle={styles.inputStyle}
              />
              <UILayout display-if={lastNameError} j-flex-center position="absolute" top="40px">
                <UIText font="ADPortsGroupBold, sans-serif" size="10px" color="#D11800" text={lastNameError} />
              </UILayout>
            </UILayout>
          </UILayout>

          <UILayout margin="10px 0 10px 0" display-if={isCreateOrEditModal}>
            <UILayout width="50%" center>
              <UIText size="14px" color="#000000" translate={messages.gender} />
            </UILayout>
            <UILayout width="50%" position="relative">
              <RadioButtonGroup
                dataSource={genderDataSource}
                onCheck={this.handleGenderCheck}
                selectedValue={gender}
                styles={styles}
              />
            </UILayout>
          </UILayout>

          <UILayout margin="10px 0 10px 0" display-if={isCreateOrEditModal}>
            <UILayout width="50%" center>
              <UIText size="14px" color="#000000" translate={messages.dateOfBirth} />
            </UILayout>
            <UILayout width="50%" position="relative">
              <UITextField
                id="birthday"
                value={this.state.birthday}
                onBlur={this.handleBirthdayBlur}
                change={this.handleBirthdayChange}
                hintText="DD-MM-YYYY"
                style={styles.textField}
                inputStyle={styles.inputStyle}
              />
              <UILayout display-if={birthdayError} j-flex-center position="absolute" top="40px">
                <UIText font="ADPortsGroupBold, sans-serif" size="10px" color="#D11800" text={birthdayError} />
              </UILayout>
            </UILayout>
          </UILayout>

          <UILayout column margin="15px 0 20px 0">
            <UILayout margin="0 0 10px 0">
              <UIText size="14px" color="#000000" translate={messages.belongsToGroupLabel} />
            </UILayout>
            <GroupPicker
              id="group-picker"
              hintText={translate(messages.groupPickerHintText)}
              onChange={this.handleSelectGroupsChange}
              selectedGroupIds={selectedGroupIds}
              allGroups={allGroups}
            />
          </UILayout>
        </UILayout>

        <UILayout center j-flex-center padding="30px 0 0 0" borderTop="1px solid #C1CADE">
          <UIButton
            click={this.handleDoneButtonClick}
            disabled={shouldDisableDoneButton}
            height="45px"
            width="160px"
            borderRadius="6px"
          >
            <UILayout j-flex-center center height="100%">
              <UIText
                font="ADPortsGroupBold, sans-serif"
                color="white"
                size="20px"
                textTransform="uppercase"
                translate={messages.doneButtonLabel}
              />
            </UILayout>
          </UIButton>
        </UILayout>
      </UILayout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    allGroups: getGroups(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addMember: (memberData) => dispatch(addMember(memberData)),
    modifyMember: (memberData) => dispatch(modifyMember(memberData)),
    closeModal: () => dispatch(showModal('')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditPassengerDataModal)
