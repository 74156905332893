import React from 'react'
import TicketsFormContainer from '../../../Booking/forms/TicketsForm/TicketsFormContainer'
import AddOrEditPassengerTicketsModalContent from './AddOrEditPassengerTicketsModalContent'

const AddOrEditPassengerTicketsModal = (props) => {
  const { editMode, alternativeResidentPriceCategory, handleDriverFromReserveCase, areVehiclesAvailable, ticketsType } = props

  return (
    <AddOrEditPassengerTicketsModalContent
      icon="iconPassenger"
      editMode={editMode}
      alternativeResidentPriceCategory={alternativeResidentPriceCategory}
      handleDriverFromReserveCase={handleDriverFromReserveCase}
      areVehiclesAvailable={areVehiclesAvailable}
      isAddon={false}
      ticketsType={ticketsType}
    />
  )
}

export default TicketsFormContainer(AddOrEditPassengerTicketsModal)
