import { Input, Select, withStyles } from '@material-ui/core'
import { UILayout, UIText } from 'bora-material-ui'
import { MenuItem } from 'material-ui'
import React from 'react'
import PicketOption from './PicketOption'
import { getGroupNameByGroupId } from './utils'
import GroupPickerIcon from './GroupPickerIcon'

const styles = {
  underline: {
    '&:after': { display: 'none' },
    '&:before': { display: 'none' },
  },
  inputRoot: {
    width: '100%',
    color: '#000000',
    background: 'white',
    boxShadow: 'none',
    height: '36px',
    minHeight: 'auto',
    border: '1px solid #D4D4D4',
    borderRadius: '4px',
  },
  select: {
    paddingLeft: '5px',
    minHeight: '25px',
    '&:focus': {
      background: 'transparent',
    },
  },
  menuItem: (isSelected) => ({
    padding: '0',
    minHeight: '36px',
    lineHeight: '36px',
    userSelect: 'none',
    fontFamily: isSelected ? 'ADPortsGroupBold, sans-serif' : 'ADPortsGroup, sans-serif',
    color: '#000000',
    fontSize: '14px',
  }),
}

class GroupPicker extends React.Component {
  render() {
    const { id, hintText, selectedGroupIds, allGroups, onChange, classes } = this.props
    const selectClasses = { select: classes.select }
    const inputClasses = {
      underline: classes.underline,
      root: classes.inputRoot,
    }
    const MenuProps = {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    }

    return (
      <UILayout column>
        <Select
          id={id}
          classes={selectClasses}
          multiple
          IconComponent={() => <GroupPickerIcon />}
          value={selectedGroupIds}
          onChange={onChange}
          input={<Input classes={inputClasses} id="select-multiple-values" />}
          renderValue={(selected) => (
            <UILayout wrap>
              {selected.map((selectedGroupId) => {
                const groupName = getGroupNameByGroupId(allGroups, selectedGroupId)
                return <PicketOption key={selectedGroupId} groupName={groupName} />
              })}
            </UILayout>
          )}
          MenuProps={MenuProps}
        >
          {allGroups.map((group) => {
            const { companyId, legalName } = group
            const isSelected = selectedGroupIds.includes(companyId)
            return (
              <MenuItem key={companyId} value={companyId} style={styles.menuItem(isSelected)}>
                {legalName}
              </MenuItem>
            )
          })}
        </Select>
        <UILayout margin="3px 0 0 0">
          <UIText size="12px" color="#B7B7B7" text={hintText} />
        </UILayout>
      </UILayout>
    )
  }
}

export default withStyles(styles)(GroupPicker)
