/* eslint-disable no-param-reassign */
import { UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import { showModal } from '../../../../actions'
import messages from '../../../../consts/messages'
import { getSelectedTicketsValues } from '../../../../services/pricing/reducers/selectors'
import { getErrorDetails, getModifying } from '../../../../services/reservation/selectors'
import { normalizePrices } from '../../../../utils/priceUtils'
import { VehiclesForm } from '../../../Booking/forms/VehiclesForm'
import { selectors } from '../../../Booking/provider'
import * as R from 'ramda'
import { ModalError } from '../ModalError'

export const getErrorCodeFromProps = R.propOr(null, 'code')

const style = getComputedStyle(document.body)

const fontSizeStrong = style.getPropertyValue('--fs-base-strong')
const fontBold = style.getPropertyValue('--font-bold')
const colorPrimaryBase = style.getPropertyValue('--color-primary-base')

class AddOrEditVehicleModalContent extends React.Component {
  constructor(props) {
    super(props)
    this.elementAfterButton = React.createRef()
  }

  state = {
    hasError: false,
    error: null,
    isVehicleAddingProcess: [
      {
        flag: false,
        formName: 'vehiclesForm',
      },
      {
        flag: false,
        formName: 'trailersForm',
      },
    ],
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTickets.length < this.props.selectedTickets.length && !this.props.errorCode) {
      this.props.showModal('')
    }
  }

  handleVehicleAddingProcess = (form) => {
    this.setState((prevState) => {
      const objectToChange = prevState.isVehicleAddingProcess.find((it) => it.formName === form)
      return {
        ...prevState,
        isVehicleAddingProcess: [
          prevState.isVehicleAddingProcess.find((it) => it.formName !== form),
          { ...objectToChange, flag: !objectToChange.flag },
        ],
      }
    })
  }

  scrollToDoneButton = () => {
    this.elementAfterButton.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  render() {
    return (
      <UILayout
        width="780px"
        column
        data-test="addOrEditVehicleTickets-modal"
        bgColor="white"
        sm={{ width: '710px' }}
        xs={{ width: '100%' }}
      >
        <UILayout padding="20px 0" borderBottom="1px solid #C1CADE" center>
          <UIText
            font={fontBold}
            color={colorPrimaryBase}
            size={fontSizeStrong}
            translate={messages.addOrEditVehicleModalTitle}
          />
        </UILayout>

        {/* <UILayout display-if={vehicleTickets.length} column padding="20px 0" borderBottom="1px solid #C1CADE">
          {vehicleTickets.map((vehicleTicket, id) => (
            <VehicleTicketRow key={id} vehicleTicket={vehicleTicket} />
          ))}
        </UILayout> */}
        <ModalError errorCode={this.props.errorCode} />

        <UILayout column padding="20px 0" borderBottom="1px solid #C1CADE">
          <VehiclesForm
            scrollToDoneButton={this.scrollToDoneButton}
            handleVehicleAddingProcess={this.handleVehicleAddingProcess}
            errorCode={this.props.errorCode}
          />
        </UILayout>
        <span ref={this.elementAfterButton}></span>
      </UILayout>
    )
  }
}

const mapStateToProps = (state) => {
  const ticketsFormValues = getSelectedTicketsValues(state)
  const selectedTickets = Object.keys(ticketsFormValues)
    .map((key) => ticketsFormValues[key])
    .filter((ticket) => typeof ticket === 'object' && ticket.code !== 'CHARTER')
  const fetchingModify = getModifying(state)
  const prices = normalizePrices(selectors.getPrices(state))

  const errorDetails = getErrorDetails(state)

  return {
    prices,
    fetchingModify,
    selectedTickets,
    errorCode: getErrorCodeFromProps(errorDetails),
  }
}

export default connect(mapStateToProps, { showModal })(AddOrEditVehicleModalContent)
