import React, { Fragment } from 'react'
import { UIButton, UILayout } from 'bora-material-ui'
import PropTypes from 'prop-types'
import messages from '../../../consts/messages'
import { connect } from 'react-redux'
import { actions } from '../provider'
import { Panel } from '../../../components'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'
import { getMaritimeStyle } from '../../../utils/maritimeStyleUtils'

const style = getComputedStyle(document.body)
const colorPrimaryBase = style.getPropertyValue('--color-primary-base')

const NoVehicleModal = ({ showModal }, { intl }) => {
  const handlePrevClick = () => {
    showModal('')
    window.scrollTo(0, 0)
  }

  const styles = {
    bluewave: { color: '#2D3955', fontWeight: 700, padding: '24px 0 0 0', fontSize: 18 },
  }

  return (
    <Fragment>
      <Panel style={getMaritimeStyle(styles.bluewave, getStyle(styles.liinilaevad))}>
        {intl.formatMessage(messages.reservationWithoutVehicleAlert)}
      </Panel>
      <UILayout height="100%" bgColor="transparent" j-flex-center padding="2rem">
        <UIButton
          click={handlePrevClick}
          background={colorPrimaryBase}
          color="#fff"
          width="88px"
          height="50px"
          column
          j-flex-center
        >
          <UILayout column height="100%" bgColor="transparent" j-flex-center>
            {intl.formatMessage(messages.ok)}
          </UILayout>
        </UIButton>
      </UILayout>
    </Fragment>
  )
}

NoVehicleModal.contextTypes = {
  intl: PropTypes.object,
}

NoVehicleModal.propTypes = {
  showModal: PropTypes.func.isRequired,
}

export default connect(null, { ...actions })(NoVehicleModal)
