import React, { Fragment } from 'react'
import { UIBottomSlider } from 'bora-material-ui'
import PropTypes from 'prop-types'
import messages from '../../../consts/messages'
import { connect } from 'react-redux'
import { actions } from '../provider'
import { Panel } from '../../../components/Panel'
import { getCurrentReservation } from '../../../services/reservation/selectors'
import { confirm } from '../../../services/reservation/actions'
import { goToPage } from '../../../actions'
import { getEditableTrip } from '../../../services/user-selections'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'
import { getMaritimeStyle } from '../../../utils/maritimeStyleUtils'

const Confirmation = ({ showModal, confirm, currentReservation = {} }, { intl }) => {
  const handlePrevClick = () => {
    showModal('')
    window.scrollTo(0, 0)
  }

  const handleNextClick = () => {
    showModal('')

    const { status = '', reservationTempId, reservationId } = currentReservation

    if (status === 'OFFER') {
      confirm({ reservationId: reservationTempId })
    } else {
      confirm({ reservationId })
    }
  }

  const styles = {
    admaritime: { color: '#2D3955', fontWeight: 700, padding: '24px 0 0 0', fontSize: 18 },
    liinilaevad: { color: '#0000A0', fontWeight: 700, padding: '24px 24px 0 24px', fontSize: 18 },
  }

  return (
    <Fragment>
      <Panel style={getMaritimeStyle(styles.admaritime, getStyle(styles.liinilaevad))}>
        {intl.formatMessage(messages.vehicleWithoutDriverConfirm)}
      </Panel>
      <UIBottomSlider
        isPopup={true}
        isNextAvailable={true}
        nextAction={handleNextClick}
        prevAction={handlePrevClick}
        prevTitle={intl.formatMessage(messages.vehicleWithoutDriverConfirmNo)}
        nextTitle={intl.formatMessage(messages.vehicleWithoutDriverConfirmYes)}
        editMode
      />
    </Fragment>
  )
}

Confirmation.contextTypes = {
  intl: PropTypes.object,
}

Confirmation.propTypes = {
  currentReservation: PropTypes.object.isRequired,
  editableTrip: PropTypes.any.isRequired,
  confirm: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    currentReservation: getCurrentReservation(state),
    editableTrip: getEditableTrip(state),
  }),
  { ...actions, confirm, goToPage }
)(Confirmation)
