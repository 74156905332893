export const knownErrors = {
  METHOD_ARGUMENT_NOT_VALID: 'loginFailedUserNotValid',
  NO_DISCOUNT_ON_ROUTE: 'localIDNotFound',
  DISCOUNT_IS_EXPIRED: 'localIDExpired',
  VEHICLE_DISCOUNT_ALREADY_USED_ON_SAIL: 'localIDAlreadyApplied',
  DISCOUNT_ALREADY_USED_ON_SAIL: 'localIDAlreadyApplied',
  DISCOUNT_ALREADY_USED_IN_RESERVATION: 'discountUsedForReservation',
  FAILED_TO_ALLOCATE_PAX_INVENTORY: 'failToAllocate',
  FAILED_TO_ALLOCATE_RESERVE_INVENTORY: 'failToAllocateResident',
  FAILED_TO_ALLOCATE_CAR_DECK_INVENTORY: 'customWeightFormError',
  FAILED_TO_ALLOCATE_CAR_DECK_INVENTORY_BY_WEIGHT: 'customWeightFormError',
  FAILED_TO_ALLOCATE_INVENTORIES: 'failToAllocateInventories',
  PROMOTION_NOT_FOUND: 'failedToApplyPromotion',
  CORPORATE_JOIN_TOKEN_NOT_AVAILABLE: 'corporateJoinTokenNotAvailable',
  CORPORATE_JOIN_PERSONAL_ID_NOT_VALID: 'corporateJoinPersonalIdNotValid',
  USER_NOT_VALID: 'loginFailedUserNotValid',
  USER_NOT_AUTHORIZED: 'loginFailedUserNotExisting',
  REMOVED_ALL_ITEMS: 'removedAllItemsError',
  EXCLUDED_PRICE_CATEGORY: 'excludedPriceCategory',
  MOBILE_ID_CANCELLED_BY_USER: 'mobileIdCancelledByUser',
  MOBILE_ID_NOT_A_CLIENT_OR_REVOKED: 'mobileIdNotAClient',
  MOBILE_ID_TIMEOUT: 'mobileIdTimeOut',
  MOBILE_ID_PHONE_NOT_AVAILABLE: 'mobileIdPhoneNotAvailable',
  MOBILE_ID_COMMUNICATION_ERROR: 'mobileIdCommunicationError',
  MOBILE_ID_INVALID_USER_CONFIGURATION: 'mobileIdInvalidUserConfiguration',
  MOBILE_ID_CLIENT_ERROR: 'mobileIdClientError',
  MOBILE_ID_INTERNAL_ERROR: 'mobileIdInternalError',
  MOBILE_ID_INVALID_PERSONAL_IDENTIFICATION_NUMBER: 'mobileIdInvalidPersonalId',
  MOBILE_ID_INVALID_PHONE_NUMBER: 'mobileIdInvalidPhoneNumber',
  RESERVATION_ALREADY_LOCKED: 'reservationLockedError',
  RESERVATION_NOT_FOUND: 'reservationNotFoundError',
  INVALID_RESERVATION_STATUS: 'reservationInvalidStatusError',
  NO_CAR_DECKS_AND_BICYCLES_ON_SAIL: 'noCarsAndBicyclesOnSail',
  ACCOUNT_ALREADY_ACTIVATED: 'loginFailedAlreadyActivated',
  ACCOUNT_ALREADY_EXISTS: 'signUpUserExists',
  CANCELLED_BY_USER: 'cancelledByUserErrorMessage',
  RESERVATION_EDIT_NOT_ALLOWED: 'ticketSalesAreAlreadyClosedMessage',
}

const unrecoverableErrors = ['RESERVATION_ALREADY_LOCKED', 'RESERVATION_NOT_FOUND', 'INVALID_RESERVATION_STATUS']

export const isUnrecoverableError = (errorCode) => unrecoverableErrors.includes(errorCode)
